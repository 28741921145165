<!--
 * @Author: Musa Tabitay
 * @Date: 2022-01-02 18:33:00
 * @LastEditTime: 2022-04-17 16:26:34
-->
<template>
  <div class="article-list">
    <!-- 加载中 -->
    <div v-if="loading" class="loading-wrap">
      <van-loading color="#3296fa" vertical>مازمۇن ىزدەلۋدە...</van-loading>
    </div>
    <template v-if="!loading">
      <div class="article-info">
        <van-image class="article-cover" :src="detail.image" fit="cover" />
        <div class="article-title">{{ detail.title }}</div>
        <div class="created_at">
          <van-icon name="clock-o" /> {{ detail.created_at }}
          <van-icon style="margin-left: 10px" name="browsing-history-o" />
          {{ detail.views }}
        </div>
      </div>

      <div
        class="teacher"
        v-if="detail.teacher"
        @click="$router.push({
            name: 'teacher',
            params: { teacher_id: detail.teacher.id },
          })
        "
      >
        <van-image
          lazy-load
          round
          class="avatar"
          :src="detail.teacher.avatar ? detail.teacher.avatar : '../../assets/avatar.jpg'"
          fit="cover"
        />
        <div>
          <h2>{{ detail.teacher.title }}</h2>
          <p>{{ detail.teacher.intro }}</p>
        </div>
      </div>

      <van-tabs v-model="active" class="tabs">
        <van-tab title="ءتىزىم" class="list">
          <van-cell
            class="article-item"
            @click="onTapToLesson(article)"
            v-for="(article, index) in list"
            :key="index"
          >
            <div
              slot="title"
              class="title van-multi-ellipsis--l2"
              :class="
                detail.is_free == 1 && article.is_free == 2 ? 'text-grey' : ''
              "
            >
              <p style="display: inline-block; margin-left: auto">
                {{ article.title }}
              </p>
              <!--
                          course   lesson
                    收费     1        2
                    免费     2        1
                    免费     2        2
                    免费     1        1
               -->
              <i
                v-if="
                  (detail.is_free == 1 && article.is_free == 1) ||
                  (detail.is_free == 2 && article.is_free == 2) ||
                  (detail.is_free == 2 && article.is_free == 1)
                "
                class="icon-lock-open1"
                style="color: #999"
              ></i>
              <i
                v-if="detail.is_free == 1 && article.is_free == 2"
                class="icon-lock"
              ></i>
            </div>
          </van-cell>
          <van-empty
            description="قازىرشە ساباق قوسىلمادى"
            v-show="!list.length"
          />
        </van-tab>
        <van-tab class="description" title="تۇسىنكتەمە">
          <div
            class="markdown-body"
            v-if="detail.description"
            v-html="detail.description"
          ></div>
          <p v-else>قازىرشە تۇسنىكتەمە جازىلمادى!</p>
        </van-tab>
      </van-tabs>

      <van-goods-action>
        <van-goods-action-icon icon="chat-o" text="كومەك" to="/help" />
        <van-goods-action-icon icon="wap-home-o" text="باس بەت" to="/" />
        <van-goods-action-icon
          :icon="collecStatus ? 'star' : 'star-o'"
          :text="collecStatus ? 'ساقتالدى' : 'ساقتاۋ'"
          @click="collecCourse"
          :color="collecStatus ? '#ff5000' : ''"
          v-if="detail.is_free == 1 && userInfo.token"
        />
        <van-goods-action-button
          type="danger"
          text="ساتىپ الۋ"
          @click="showPay = true"
          v-if="detail.is_free == 1 && userInfo.token"
        />
        <van-goods-action-button
          :color="collecStatus ? '#be99ff' : '#7232dd'"
          :text="collecStatus ? 'ساقتالدى' : 'ساقتاۋ'"
          @click="collecCourse"
          v-if="detail.is_free == 2"
        />
      </van-goods-action>

      <van-popup class="payContainer" v-model="showPay">
        <div>
          <h4>{{ detail.title }}</h4>
          <p class="p1">جالپى <span>{{ list.length }}</span> مازمۇن، باعاسى <span>{{ detail.price }}</span> يۋان</p>
          <!-- <p class="p2" v-if="detail.is_vip == 1">(تەك ءبىر اق رەت ساتىپ الاسىز)</p> -->
          <p class="p3" v-if="detail.is_vip == 1">(VIP مۇشە بولساڭىز تەگىن كورەسىز)</p>
          <p class="p3" v-if="detail.is_vip == 2">(بۇل مازمۇن تەك جەكە ساتىلادى)</p>
          <p class="p4">
            <template v-if="detail.is_vip == 1">
              <van-button type="danger" round @click="_pay" size="small">جەكە ساتىپ الۋ</van-button>
              <van-button type="primary" round to="/vip" size="small">VIP مۇشە بولۋ</van-button>
            </template>
            <van-button
              block
              v-if="detail.is_vip == 2"
              type="danger"
              round
              size="small"
              @click="_pay"
            >جەكە ساتىپ الۋ</van-button>
          </p>
        </div>
      </van-popup>
    </template>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { initShareInfo } from '@/utils/util'
import { getArticles, courseDetail } from '@/api/article'
import { pay, payResult } from '@/api/pay'
import { wechatRedirect, collecStatus, colAdd, colCancel } from '@/api/user'
import { getItem } from '@/utils/storage'

export default {
  name: 'ArticleList',
  provide () {
    return {
      id: this.id
    }
  },
  props: {
    id: {
      type: [Number, String, Object],
      required: true
    }
  },
  data () {
    return {
      list: [], // 文章列表数据
      loading: true, // 上拉加载更多的 loading 状态
      finished: false, // 是否加载结束
      error: false, // 是否加载失败
      detail: {}, // 文章详情
      active: 2,
      userInfo: {}, // 用户信息
      collecStatus: false,
      showPay: false
    }
  },

  mounted () {
    setTimeout(() => {
      const userInfo = getItem('userInfo')
      this.userInfo = userInfo
      if (userInfo.token) {
        this.getCollecStatus()
        this.getCourseDetail()
      }
    }, 500)
  },

  methods: {
    // 当触发上拉加载更多的时候调用该函数
    async onLoad () {
      try {
        // 1. 请求获取数据
        const { data } = await getArticles({
          vid: this.id,
          m_show: 'N'
        })

        // 2. 把数据添加到 list 数组中
        const results = data.data
        this.list = results.reverse()

        // 3. 设置本次加载中 loading 状态结束
        this.loading = false

        // 4. 判断数据是否加载结束
        if (!results.length) this.finished = true
      } catch (err) {
        this.loading = false // 关闭 loading 效果
        this.error = true // 开启错误提示
        console.error(err)
      }
    },

    async getCourseDetail () {
      try {
        this.loading = true
        const { data } = await courseDetail({
          vid: this.id
        })
        this.detail = data.course
        this.onLoad()

        wx.ready(() => {
          initShareInfo({
            title: `${data.course.title} - ارنا ساباقحاناسى`, // 分享标题
            imgUrl: data.course.image // 分享图标
          })
        })
      } catch (error) {
        console.error(error)
      }
    },

    async getCollecStatus () {
      try {
        const { data } = await collecStatus(this.id)
        this.collecStatus = data.collec_status
      } catch (error) {
        console.log(error)
      }
    },

    // 收藏和取消收藏
    async collecCourse () {
      try {
        if (this.collecStatus) {
          await colCancel(this.id)
        } else {
          await colAdd(this.id)
        }
        this.collecStatus = !this.collecStatus
      } catch (error) {
        console.log(error)
      }
    },

    async onTapToLesson (article) {
      if (
        (parseInt(this.detail.is_free) === 2 &&
          parseInt(article.is_free) === 2) ||
        (parseInt(this.detail.is_free) === 2 &&
          parseInt(article.is_free) === 1) ||
        (parseInt(this.detail.is_free) === 1 && parseInt(article.is_free) === 1)
      ) {
        return this.$router.push({
          name: 'detail',
          params: {
            artId: article.id,
            parent_id: this.detail.id,
            type: this.detail.type
          }
        })
      }

      if (
        parseInt(this.detail.is_free) === 1 &&
        parseInt(article.is_free) === 2 &&
        this.userInfo.token
      ) {
        this.showPay = true
      }
    },

    async _pay () {
      const _this = this
      _this.showPay = false
      const openId = _this.$cookies.get('openId')
      if (!openId) {
        location.href = `${wechatRedirect}?url=${encodeURIComponent(
          location.href
        )}&scope=snsapi_userinfo`
      }

      // 支付请求参数
      const params = {
        goods_name: _this.detail.title,
        goods_id: _this.detail.id,
        price: _this.detail.price,
        openId
      }
      const { code, data } = await pay(params)
      if (code === 200) {
        wx.chooseWXPay({
          timestamp: data.args.timeStamp,
          nonceStr: data.args.nonceStr,
          package: data.args.package,
          signType: data.args.signType,
          paySign: data.args.paySign,
          success (res) {
            if (res.errMsg === 'chooseWXPay:ok') {
              payResult({
                payState: 'success',
                goods_name: _this.detail.title,
                goods_id: _this.detail.id,
                price: _this.detail.price,
                order_number: data.orderInfo.order_number
              })

              setTimeout(() => {
                _this.getCourseDetail()
              }, 500)
            } else if (res.errMsg === 'chooseWXPay:fail') {
              _this._userCancel()
            }
          },
          cancel: function () {
            _this._userCancel()
          },
          fail: function (res) {
            alert(res.message || 'اقشا تولەۋ ءساتسىز بولدى')
          }
        })
      }
    },

    // 用户取消付款提示
    async _userCancel () {
      try {
        await this.$dialog.confirm({
          title: 'ەسكەرتۋ',
          message:
            'ءسىز اقشا تولەۋدى كۇشىنەن قالدىردىڭىز، قايتالاي اقشا تولەۋدى باسىڭىز!',
          confirmButtonText: 'اقشا تولەۋ',
          cancelButtonText: 'قالدىرۋ'
        })
        this._pay()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "./css/github-markdown.css";
.article-list {
  .article-info {
    padding: 20px;
    background-color: #fff;
    padding-bottom: 20px;
    margin-bottom: 10px;

    .article-cover {
      width: 100%;
      /deep/ .van-image__img {
        width: 100%;
        max-height: 400px;
        border-radius: 12px !important;
        object-fit: cover;
      }
    }

    .article-title {
      font-size: 32px;
      color: #333;
      direction: rtl;
      text-align: right;
      padding: 0 20px 20px;
      background-color: #fff;
      margin-bottom: 10px;
    }

    .created_at {
      font-size: 24px;
      color: #999;
      font-family: Arial, Helvetica, sans-serif;
      padding-left: 10px;
    }
  }

  // 教师模块
  .teacher {
    display: flex;
    align-items: center;
    direction: rtl;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 10px;
    .avatar {
      width: 100px;
      height: 100px;
      flex-shrink: 0;
      margin-left: 20px;
    }
    > div {
      display: flex;
      flex-direction: column;
      h2 {
        font-size: 30px;
        margin-bottom: 6px;
      }

      p {
        font-size: 24px;
        color: #999;
      }
    }
  }

  // 切换模块
  .tabs {
    direction: rtl;
    padding-bottom: 100px;
    .title {
      font-size: 30px;
      > i {
        margin-right: 20px;
      }
    }
    .description {
      font-size: 26px !important;
      padding: 20px 30px;
      background-color: #fff;
      // text-indent: 2em;
      img {
        width: 100% !important;
      }
    }

    .list {
      font-size: 26px;
      padding-top: 10px;
      background-color: #fff;
    }
  }

  /deep/ .van-goods-action {
    border-top: 1px solid #eae9e9;
  }

  .payContainer {
    width: 600px;
    border-radius: 20px;
    text-align: center;
    padding: 40px 30px 30px 30px;
    box-sizing: border-box;
    direction: rtl;
    h4 {
      font-size: 32px;
      margin-bottom: 20px;
    }

    p.p1 {
      font-size: 28px;
      margin-bottom: 20px;
      span {
        color: #ee0a24;
      }
    }

    p.p2 {
      font-size: 26px;
      margin-bottom: 20px;
      color: #999;
    }

     p.p3 {
      font-size: 26px;
      color: #999;
      margin-bottom: 30px;
    }

    p.p4 {
      display: flex;
      justify-content: space-around;
      align-items: center;
      direction: rtl;
    }
  }
}
</style>
